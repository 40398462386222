
import {Component, Vue} from 'vue-property-decorator';
import multiSelect from '@/components/multiSelect/index.vue';
import {applications, applicationRequests} from '../../../../resources';
import {ObjectId} from 'bson';
import {Form} from 'element-ui';
import {LushpayConfig} from '@/externals/MaxCI-ApplicationRequest-v1';
@Component({
  name: 'appConfig',
  components: {
    multiSelect,
  },
})
export default class extends Vue {
  private dialogPay = false;
  private validprice = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rule: any,
    value: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    callback: (error?: Error) => void,
  ) => {
    if (!/(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/.test(value)) {
      callback(new Error('价格格式有误'));
    } else {
      if (Number(value) > 0) {
        callback();
      } else {
        callback(new Error('请输入大于0的金额'));
      }
    }
  };
  private validProportion = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rule: any,
    value: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    callback: (error?: Error) => void,
  ) => {
    if (!/^([1-9]\d+|[1-9])$/.test(value)) {
      callback(new Error('请输入大于1的正整数'));
    } else {
      callback();
    }
  };
  private validInitialAmount = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rule: any,
    value: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    callback: (error?: Error) => void,
  ) => {
    if (!/^(?!(0[0-9]{0,}$))[0-9]{1,}[.]{0,}[0-9]{0,}$/.test(value)) {
      callback(new Error('请输入大于0的金额'));
    } else {
      callback();
    }
  };
  private activeNames = '1';
  private availableServices: Array<string> = [];
  private lushpayConfig = {} as null | LushpayConfig;
  private lushpayData = {
    partnerId: '',
    secret: '',
  };
  private lushpayRules = {
    partnerId: [{required: true, message: '请输入服务商Id', trigger: 'blur'}],
    secret: [{required: true, message: '请输入服务商密钥', trigger: 'blur'}],
  };
  private formData = {
    minimumWithdrawal: 0 as null | number, //最低提现金额
    settlementCycle: null as null | number, //结算周期
  };
  private rules = {
    minimumWithdrawal: [
      {required: true, message: '请输入最低提现金额', trigger: 'blur'},
      {validator: this.validprice, trigger: 'blur'},
    ],
    settlementCycle: [
      {required: true, message: '请输入最低提现金额', trigger: 'blur'},
    ],
  };
  private customerConfig: Array<{
    name: string;
    phone: string;
  }> = [];
  private applicationId = ObjectId.createFromHexString(
    this.$route.params.applicationId,
  );
  async created() {
    this.checkApplication();
  }
  private async checkApplication() {
    try {
      const application = (
        await applications.find(stage =>
          stage.$match(match => {
            match(
              f => f('_id'),
              e =>
                e.$eq(
                  ObjectId.createFromHexString(
                    this.$route.params.applicationId,
                  ),
                ),
            );
            return match;
          }),
        )
      ).find(() => true);
      this.availableServices =
        application?.spec?.quota?.availableServices ?? [];
      this.customerConfig = application?.spec?.config?.customerConfig ?? [];
      this.lushpayConfig = application?.spec?.config?.lushpay ?? null;
      if (application?.spec?.config?.lushpay) {
        this.lushpayData = application?.spec?.config?.lushpay;
      }
      this.formData.minimumWithdrawal =
        application?.spec?.config?.minimumWithdrawal !== undefined
          ? application?.spec?.config?.minimumWithdrawal / 100
          : null;
      this.formData.settlementCycle =
        application?.spec?.config?.settlementCycle ?? 1;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    }
  }
  //支付配置弹窗
  private payPopup() {
    this.dialogPay = true;
    this.lushpayData = {
      partnerId: '',
      secret: '',
    };
  }
  //支付配置保存
  private async submitPay() {
    (this.$refs.lushpayData as Form).validate(valid => {
      if (valid) {
        try {
          applicationRequests
            .create(
              [
                {
                  spec: {
                    type: '更新应用',
                    applicationId: this.applicationId,
                    config: {
                      lushpay: this.lushpayData,
                      minimumWithdrawal: Math.round(
                        Number(
                          this.formData.minimumWithdrawal
                            ? this.formData.minimumWithdrawal
                            : '',
                        ) * 100,
                      ),
                      settlementCycle: Number(this.formData.settlementCycle),
                    },
                  },
                },
              ],
              {
                watch: {
                  filter: filter =>
                    filter(
                      f => f('operationType'),
                      e => e.$eq('update'),
                    )(
                      f => f('fullDocument')('status')('phase'),
                      e => e.$exists(true),
                    ),
                },
                fullResource: true,
              },
            )
            .then(res => {
              if (res[0].status?.phase === '成功') {
                this.$message.success('保存成功');
                this.dialogPay = false;
                this.checkApplication();
              } else {
                this.$message.error(res[0].status?.conditions[0].message ?? '');
              }
            });
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          this.$message.error('网络异常，请稍后重试');
        }
      } else {
        return false;
      }
    });
  }
  //增加客服
  private addCustomer() {
    this.customerConfig.push({
      name: '',
      phone: '',
    });
  }
  //删除客服
  private removeCustomer(index: number) {
    this.customerConfig.splice(index, 1);
  }
  private featuresSave() {
    (this.$refs.formData as Form).validate(valid => {
      if (valid) {
        try {
          //客服验证
          let num = 0,
            errorNum = 0;
          this.customerConfig.forEach(item => {
            if (item.name === '' || item.phone === '') {
              num++;
            }
            if (!/^1[23456789]\d{9}$/.test(item.phone as string)) {
              errorNum++;
            }
          });
          if (num > 0) {
            this.$message.error('请把客服信息填写完整');
            return;
          }
          if (errorNum > 0) {
            this.$message.error('客服手机号不正确');
            return;
          }
          applicationRequests
            .create(
              [
                {
                  spec: {
                    type: '更新应用',
                    applicationId: this.applicationId,
                    config: {
                      customerConfig: this.customerConfig,
                      minimumWithdrawal: Math.round(
                        Number(
                          this.formData.minimumWithdrawal
                            ? this.formData.minimumWithdrawal
                            : '',
                        ) * 100,
                      ),
                      settlementCycle: Number(this.formData.settlementCycle),
                      lushpay: this.lushpayData,
                    },
                  },
                },
              ],
              {
                watch: {
                  filter: filter =>
                    filter(
                      f => f('operationType'),
                      e => e.$eq('update'),
                    )(
                      f => f('fullDocument')('status')('phase'),
                      e => e.$exists(true),
                    ),
                },
                fullResource: true,
              },
            )
            .then(res => {
              if (res[0].status?.phase === '成功') {
                this.$message.success('保存成功');
              } else {
                this.$message.error(res[0].status?.conditions[0].message ?? '');
              }
            });
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          this.$message.error('网络异常，请稍后重试');
        }
      } else {
        return false;
      }
    });
  }
}
