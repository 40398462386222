
import {Component, Vue} from 'vue-property-decorator';
import multiSelect from '@/components/multiSelect/index.vue';
import {applications, applicationRequests} from '../../../../resources';
import {ObjectId} from 'bson';
@Component({
  name: 'featuresSetting',
  components: {
    multiSelect,
  },
})
export default class extends Vue {
  private enableServices: (
    | '商城'
    | '配送'
    | '跑腿'
    | '团购'
    | '证件照'
    | '二手'
    | '会员卡'
    | '充值'
    | '问卷'
    | '兼职'
  )[] = [];
  private availableServices: Array<string> = [];
  async created() {
    try {
      const application = (
        await applications.find(stage =>
          stage.$match(match => {
            match(
              f => f('_id'),
              e =>
                e.$eq(
                  ObjectId.createFromHexString(
                    this.$route.params.applicationId,
                  ),
                ),
            );
            return match;
          }),
        )
      ).find(() => true);
      this.availableServices =
        application?.spec?.quota?.availableServices ?? [];
      this.enableServices = application?.spec?.enableServices ?? [];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    }
  }
  private async featuresSave() {
    try {
      if (this.enableServices.length === 0) {
        this.$message.error('请至少启用一个服务');
        return;
      }
      const applicationRequest = await applicationRequests.create(
        [
          {
            spec: {
              type: '更新应用',
              applicationId: ObjectId.createFromHexString(
                this.$route.params.applicationId,
              ),
              enableServices: this.enableServices,
            },
          },
        ],
        {
          watch: {
            filter: filter =>
              filter(
                f => f('operationType'),
                e => e.$eq('update'),
              )(
                f => f('fullDocument')('status')('phase'),
                e => e.$exists(true),
              ),
          },
          fullResource: true,
        },
      );
      if (applicationRequest[0].status?.phase === '成功') {
        this.$message.success('保存成功');
      } else {
        this.$message.error(
          applicationRequest[0].status?.conditions[0].message ?? '',
        );
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    }
  }
}
