
import {Component, Vue} from 'vue-property-decorator';
import Submenu from '@/components/Submenu/index.vue';
import featuresSetting from './components/features/index.vue';
import appInfo from './components/appInfo/index.vue';
import appConfig from './components/config/index.vue';

@Component({
  name: 'setting',
  components: {
    Submenu,
    featuresSetting,
    appInfo,
    appConfig,
  },
})
export default class extends Vue {
  private menu = '';
  private submenu = [
    {
      top: '',
      list: [
        {name: '应用信息', active: true},
        {name: '功能设置', active: false},
        {name: '应用配置', active: false},
      ],
    },
  ];
  created() {
    this.$store.state.applicationId = this.$route.params.applicationId;
    this.menu = this.$route.query.menu
      ? (this.$route.query.menu as string)
      : '应用信息';
  }
}
