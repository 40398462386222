
import {Component, Vue} from 'vue-property-decorator';
import {deliveryDevelopers} from '../../../../resources';
import {ObjectId} from 'bson';
import {getUserId} from '../../../../api/publicMethod';

@Component({
  name: 'appInfo',
})
export default class extends Vue {
  private userId: ObjectId = getUserId();
  private dialogSecret = false;
  private applicationId = this.$route.params.applicationId;
  private secret = '';
  async created() {
    this.updateSecret();
  }
  private async updateSecret() {
    const deliveryDeveloper = await deliveryDevelopers.find(stage =>
      stage.$match(match =>
        match(
          f => f('spec')('applicationId'),
          e => e.$eq(ObjectId.createFromHexString(this.applicationId)),
        )(
          f => f('spec')('userId'),
          e => e.$eq(this.userId),
        ),
      ),
    );
    if (deliveryDeveloper.length > 0) {
      this.secret = deliveryDeveloper[0].spec.secret;
    }
  }
  //生成秘钥
  private async secretSubmit() {
    const deliveryDeveloper = await deliveryDevelopers.update(
      filter =>
        filter(
          f => f('spec')('userId'),
          e => e.$eq(this.userId),
        )(
          f => f('spec')('applicationId'),
          e => e.$eq(ObjectId.createFromHexString(this.applicationId)),
        ),
      update =>
        update.$set(s =>
          s(f => f('spec')('secret'), this.randomWord(true, 32, 32)),
        ),
      {
        upsert: true,
      },
    );
    if (deliveryDeveloper.length > 0) {
      this.dialogSecret = false;
      this.$message.success('生成成功');
      this.updateSecret();
    }
  }
  private randomWord(randomFlag: boolean, min: number, max: number) {
    let str = '',
      range = min,
      arr = [
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        'a',
        'b',
        'c',
        'd',
        'e',
        'f',
        'g',
        'h',
        'i',
        'j',
        'k',
        'l',
        'm',
        'n',
        'o',
        'p',
        'q',
        'r',
        's',
        't',
        'u',
        'v',
        'w',
        'x',
        'y',
        'z',
        'A',
        'B',
        'C',
        'D',
        'E',
        'F',
        'G',
        'H',
        'I',
        'J',
        'K',
        'L',
        'M',
        'N',
        'O',
        'P',
        'Q',
        'R',
        'S',
        'T',
        'U',
        'V',
        'W',
        'X',
        'Y',
        'Z',
      ];

    // 随机产生
    if (randomFlag) {
      range = Math.round(Math.random() * (max - min)) + min;
    }
    for (let i = 0; i < range; i++) {
      const pos = Math.round(Math.random() * (arr.length - 1));
      str += arr[pos];
    }
    return str;
  }
}
